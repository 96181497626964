<video class="video" autoplay muted loop id="myVideo">
  <source src="/assets/video/chip.mp4" type="video/mp4">
</video>

<!--div fxLayout="column" fxLayoutAlign="space-between" fxFill>
  <video class="video" autoplay muted loop id="myVideo">
    <source src="/assets/video/chip.mp4" type="video/mp4">
  </video>
  <div fxFlex>
    <app-navigation></app-navigation>
  </div>
  <div fxFlex="100"  style="background-color: black;">
    
    <router-outlet ></router-outlet>
  </div>
  <div fxFlex="10" style="background-color: blue;">
    <app-footer ></app-footer></div>
</div -->



<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport [hidden]="!(isHandset$ | async)"
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="false">
      <!-- [opened]="(isHandset$ | async) === false" -->
    <div fxLayout="row" fxLayoutAlign="space-around center">
      <!--img alt="MIA logo" src="/assets/images/MIA_circle.png" width=100px / -->
    </div>
    <mat-nav-list>
      <a mat-list-item [routerLink]="['/']">Home</a>
      <a mat-list-item [routerLink]="['/about']">Over</a>
      <a mat-list-item [routerLink]="['/demos']">Demos</a>

      <!-- a mat-list-item href="mia">Ontmoet MIA</a>
      <a mat-list-item href="reader">Web reader</a>
      <a mat-list-item href="references">Referenties</a>
      <a mat-list-item [routerLink]="['/tweets']">Tweet annotation</a -->

    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content >
    <div fxLayout="column" fxLayoutAlign="space-between" fxFill>
      <div fxFlex>

    <mat-toolbar color="primary">
      <button
        *ngIf="isHandset$ | async"
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"        >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
        <!--span class="span"> <img alt="MIA logo" src="/assets/images/MIA_circle.png" width=50px />   </span-->
     <a [routerLink]="['/']" style="text-decoration: none; color:white; line-height:42px;">Sips AI</a>
        <div class='links' [hidden]="(isHandset$ | async)">
          <!-- ul class="navlist">
          <li><a [routerLink]="['/about']">Over</a></li>
          <li><a [routerLink]="['/demos']">Demos</a></li>
          </ul -->
        </div>
    </mat-toolbar>
  </div>
  <div fxFlex="100" >
    <router-outlet></router-outlet>  
  </div>
  <div fxFlex="10">

    <app-footer></app-footer>
    </div>
  </div>
  </mat-sidenav-content>

</mat-sidenav-container>
