<!--div fxLayout="column" fxLayoutAlign="space-between" fxFill>
  <video class="video" autoplay muted loop id="myVideo">
    <source src="/assets/video/chip.mp4" type="video/mp4">
  </video>
  <div fxFlex>
    <app-navigation></app-navigation>
  </div>
  <div fxFlex="100"  style="background-color: black;">
    
    <router-outlet ></router-outlet>
  </div>
  <div fxFlex="10" style="background-color: blue;">
    <app-footer ></app-footer></div>
</div -->


<app-main></app-main>
